<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-datepicker
            type="month"
            default="today"
            label="하역/선적 기간"
            name="searchMonth"
            v-model="searchParam.searchMonth"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-12">
        <c-table
          ref="gridUnload"
          title="하역/선적 현황"
          :columns="gridUnload.columns"
          :data="gridUnload.data"
          :filtering="false"
          :columnSetting="false"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getUnloadList" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'mdmUnloadTypeName'">
              <q-chip
                :color="props.row.mdmUnloadTypeName == '하역' ? 'orange' : 'blue'"
                outline square
                class="full-size-chip"
                text-color="white">
                {{props.row.mdmUnloadTypeName}}
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'unload',
  data() {
    return {
      searchParam: {
        plantCd: null,
        searchMonth: '',
      },
      gridUnload: {
        columns: [
        {
          name: 'mdmUnloadTypeName',
          field: 'mdmUnloadTypeName',
          label: '하역/선적',
          align: 'center',
          sortable: true,
          type: 'custom',
          style: 'width:100px',
        },
        {
          name: 'ownerName',
          field: 'ownerName',
          label: '화주사',
          align: 'left',
          sortable: true,
        },
        {
          name: 'shipName',
          field: 'shipName',
          label: '선명',
          align: 'left',
          style: 'width:300px',
          sortable: true,
        },
        {
          name: 'workQty',
          field: 'workQty',
          label: '작업량',
          align: 'right',
          type: 'cost',
          style: 'width:150px',
          sortable: true,
        },
        {
          name: 'startDt',
          field: 'startDt',
          label: '시작일',
          align: 'center',
          style: 'width:150px',
          sortable: true,
        },
        {
          name: 'endDt',
          field: 'endDt',
          label: '종료일',
          align: 'center',
          style: 'width:150px',
          sortable: true,
        },
      ],
        data: [],
      },
      gridShip: {
        columns: [],
        data: [],
      },
      editable: true,
      listUnloadUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUnloadUrl = selectConfig.mdm.unload.list.url

      this.getUnloadList();
    },
    getUnloadList() {
      this.$http.url = this.listUnloadUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridUnload.data = _result.data;
      },);
    },
  }
};
</script>
